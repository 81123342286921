/* apply a natural box layout model to all elements
 * https://www.paulirish.com/2012/box-sizing-border-box-ftw/
 */
:root                  { box-sizing: border-box; }
*, *::before, *::after { box-sizing: inherit; }

:root, body {
  margin: 0;
  padding: 0;
  border: initial;
}

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden; 
}
body {
  overflow: hidden;
  width: 100lvw;
  height: 100lvh;
}
body {
  height: 100dvh;
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark theme colors */
body.dark-theme {
  background-color: #000;
}

body.light-theme {
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  /* dark mode styles */
  body {
    background-color: #000;
  }

  .mp-header {
    background-color: #000;
  }
}

/* Override dark mode with light mode styles if the user decides to swap */
body.light-theme {
  background-color: #fff;
}

.light-theme .mp-header {
  background-color: #fff;
}

.headerButtons {
  display: flex;
  margin: 5px;

  /* > div {
    margin: 5px 5px 0;
  } */
}

.title-button-text {
  margin: 0 0 0 5px;
}

@media (max-width: 780px) {
  /* 780 width or smaller */
  .themeButtons {
    display: none !important;
  }
}

h3 {
  font-weight: bold !important;
}

@media (max-width: 630px) {
  /* 390 with or smaller */
  .themeButtons {
    display: none !important;
  }
  .headerButtons {
    gap: 5px !important;
  }
  .headerButtons > div {
    margin-bottom: 5px;
  }
  .title-button-text {
    display: none !important;
  }
}

.animate-ping {
	animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.render-connected {
  margin-top: 4px !important;
}